import axios from 'axios'

const global = axios.create({
  baseURL: 'https://loopaai.ru/'
})

global.interceptors.response.use((response) => {
  return response
},
error => {
  if (error.response.status === 403) {
    console.log('У вас недостаточно прав')
  }

  return Promise.reject(error)
})

export default global
