import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VModal from 'vue-js-modal'
import { globalComponents } from '@/common/globalComponents'
import Toasted from 'vue-toasted'
/*
* FontAwesome
* */
import { library } from '@fortawesome/fontawesome-svg-core'
import { icons } from '@/common/fontAwesomeIcon'

Vue.config.productionTip = false

library.add(icons)

Vue.use(globalComponents)
Vue.use(VModal, { componentName: 'vm-modal' })
Vue.use(Toasted)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
