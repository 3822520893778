<template>
  <footer class="page-footer">
    <div class="container page-footer__inner">
      <ui-icon icon="logo-description-footer" class="page-footer__logo" />

      <div class="page-footer__contacts">
        <div class="page-footer__email">info@loopaai.ru</div>
        <div class="page-footer__address">ФТ "Сириус", проезд Триумфальный, д.1</div>
      </div>

      <div class="page-footer__rights">
        <a
          href="https://loopaai.ru"
          target="_blank"
        >
          Политика<br />
          конфиденциальности
        </a>
        <span
        >© 2024 LOOPA.<br />
          Все права защищены</span
        >
      </div>
    </div>
  </footer>
</template>

<script>
import UiIcon from '@/components/UiIcon'

export default {
  name: 'TheFooter',
  components: {
    UiIcon
  }
}
</script>

<style lang="scss">
.page-footer {
  color: var(--white);
  background-color: var(--color-bg-footer);

  &__inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 48px 0;
    min-height: 148px;

    @media (max-width: 992px) {
      align-items: flex-start;
    }
    @media (max-width: 576px) {
      flex-direction: column;
      padding: 36px 0;
    }
  }

  &__logo {
    width: 130px;
    height: 50px;
  }

  &__contacts {
    display: flex;
    align-content: center;
    justify-content: space-between;
    min-width: 700px;
    margin: 0 auto;
    text-align: left;

    @media (max-width: 1500px) {
      margin: 0 0 0 auto;
    }
    @media (max-width: 992px) {
      flex-direction: column;
      align-items: flex-end;
      min-width: inherit;
      margin: 10px 0 0 auto;
    }
    @media (max-width: 576px) {
      margin: 20px 0 0;
      align-items: flex-start;

    }
  }

  &__number {
    display: flex;
    align-items: center;

    @media (max-width: 576px) {
      margin-bottom: 5px;
    }
  }

  &__email {
    @media (max-width: 576px) {
      margin-bottom: 5px;
    }
  }
  &__number-icon {
    display: flex;
    margin-right: 5px;

    & img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      filter: invert(1);
    }
  }

  &__rights {
    display: flex;
    justify-content: space-between;
    width: 542px;
    padding-right: 105px;

    @media (max-width: 1500px) {
      flex: 1 0 100%;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-items: flex-end;
      width: auto;
      padding: 0;
      margin-top: 10px;

      br {
        display: none;
      }
    }

    @media (max-width: 992px) {
      margin-top: 20px;
    }

    @media (max-width: 576px) {
      align-items: flex-start;
    }

    a {
      &, &:visited {
        color: inherit;
      }

      @media (max-width: 1500px) {
        margin: 5px 0 0 0;
        font-size: 14px;

        br {
          display: none;
        }
      }
    }
  }
}
</style>
