<template>
  <section
    class="file-upload"
    @dragover.prevent.stop
    @drop.prevent.stop="dropFile"
  >
    <div class="file-upload__box">
      <p class="file-upload__hint">Загрузите файлы для распознавания или перетащите их сюда</p>

      <ui-button
        class="file-upload__button"
        @click="openFileUpload"
      >
        Выберите файл
      </ui-button>

      <div class="file-upload__support">
        <p>
          Поддерживаемые форматы файлов:
          <span class="file-format">pdf, jpg, jpeg, png</span>
          до <span class="file-format">20МБ</span>
        </p>
      </div>

      <input ref="input-file" class="file-upload__input" type="file" @change="setSelectFile" />
    </div>

    <ul v-if="arrFiles.length" class="file-upload__file-list">
      <li
        v-for="(file, indexFile) of arrFiles"
        :key="indexFile"
        class="file-upload__file-item"
      >
        <strong class="file-upload__file-name">{{ file.name }}</strong>
        <div class="file-upload__file-buttons">
          <ui-button color="danger" icon="trash-alt" is-small @click="removeFile">Удалить</ui-button>
          <ui-button color="success" icon="arrow-right" is-small @click="routerPush">Далее</ui-button>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FileUpload',
  computed: {
    ...mapGetters(['arrFiles'])
  },
  methods: {
    openFileUpload () {
      this.$refs['input-file'].click()
    },
    setSelectFile (event) {
      this.$store.dispatch('setArrFiles', Array.from(event.srcElement.files))
    },
    removeFile () {
      this.$store.dispatch('setArrFiles', [])
    },
    dropFile (event) {
      const arrFile = [Array.from(event.dataTransfer.files)[0]]
      this.$store.dispatch('setArrFiles', arrFile)
    },
    routerPush () {
      this.$router.push({ name: 'Select' })
    }
  }
}
</script>

<style lang="scss">
.file-upload {
  &__box {
    color: var(--text-secondary);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    padding: 30px;
    border: 1px dotted var(--border);
    background-size: 24px 24px;
    background-position: 0 0,0 12px,12px -12px,-12px 0;
  }

  &__input {
    display: none;
    position: absolute;
    top: -1px;
    left: -1px;
    overflow: hidden;
    width: 0;
    height: 0;
  }

  &__hint {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 23px;
  }

  &__button {
    margin-bottom: 30px;
  }

  &__support {
    font-size: 13px;
    text-align: center;
    color: var(--text-secondary);

    .file-format {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &__files-select {
    display: flex;
    flex-direction: column;
  }

  &__file-list {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: var(--white);
    box-shadow: 0 1px 5px 0 rgb(216 220 227 / 44%);
  }

  &__file-item {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 30px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
  }

  &__file-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__file-buttons {
    display: flex;
    gap: 10px;
  }
}
</style>
