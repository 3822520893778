<template>
  <div class="main-page">
    <div class="main-page__inner">
      <div class="main-page__body">
        <file-upload class="main-page__section" />

        <section class="recognition-service page-section main-page__section">
          <h3 class="page-section__title">Сервис распознавания рукописных данных</h3>
          <p class="page-section__description">
            Проект разрабатывается компанией <strong>ООО "ЛУПА"</strong>, резидентом ИНТЦ Сириус. Все модели, используемые для распознавания данных, были разработаны специалистами компании.
          </p>
          <p class="page-section__description">
            Демонстрация базовых возможностей платформы представлена на этом сайте. Для записи на демонстрацию демо, пожалуйста, свяжитесь по электронной почте, указанной в разделе <strong>контактов</strong>.
          </p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload'

export default {
  name: 'Index',
  components: {
    FileUpload
  }
}
</script>

<style lang="scss">
.main-page {
  padding: 55px 20px 50px;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
  }

  &__body {
    width: 100%;
    max-width: 850px;
  }

  &__section {
    margin-bottom: 36px;
  }
}

.page-section {
  &__title {
    text-align: center;
    margin-bottom: 24px;
    font-size: 1.5em;
    color: #333;
  }

  &__description {
    margin-bottom: 16px;
    line-height: 1.6;
    text-align: justify;
    font-size: 1.1em;
    color: #555;
  }

  &__description strong {
    color: #333;
  }
}
</style>
