<template>
  <header class="page-header">
    <div class="container page-header__inner">
      <div class="page-header__left">
        <ui-icon icon="logo-description" class="page-header__logo" />
        <h4>Сервис распознавания текста на документах</h4>
      </div>
      <div class="page-header__support">
        <span>При поддержке</span>
        <img src="@/assets/svg/support-logo.png" class="page-header__support-logo" />
      </div>
    </div>
  </header>
</template>

<script>
import UiIcon from '@/components/UiIcon'

export default {
  name: 'TheHeader',
  components: {
    UiIcon
  }
}
</script>

<style lang="scss">
.page-header {
  box-shadow: 0 0 6px 3px rgb(0 0 0 / 15%);

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    min-height: 70px;
    padding: 10px 20px;
    background-color: var(--white);

    @media (max-width: 500px) {
      flex-direction: column;
      gap: 15px;
    }
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__logo {
    width: 160px;
    margin-right: 40px;

    @media (max-width: 500px) {
      margin-right: 0;
    }
  }

  &__support {
    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
      font-size: 14px;
      color: #555;
    }

    &-logo {
      width: 100px;
      height: auto;
    }

    @media (max-width: 500px) {
      flex-direction: column;
      span {
        margin-right: 0;
        margin-bottom: 5px;
      }
    }
  }
}
</style>

