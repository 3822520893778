<template>
  <div class="select-area-controls">
    <h3 class="select-area-controls__title">Области</h3>
    <ul v-if="arrAreas.length" class="select-area-controls__list">
      <li
        v-for="(objArea, indexArea) of arrAreas"
        :key="objArea._innerId"
        class="select-area-controls__list-item"
      >
        <span>{{ `Область #${indexArea + 1}` }}</span>
        <ui-button color="danger" is-text is-small icon="trash-alt" class="select-area-controls__button" @click="$emit('removeArea', objArea._innerId)" />
      </li>
    </ul>
    <span
      v-else
      class="select-area-controls__empty"
    >
        Выделите область
    </span>

    <div class="select-area-controls__bottom">
      <ui-button is-small is-width @click="$emit('changeApi')">Выбрать модель распознавания</ui-button>
      <ui-button is-small is-width @click="$emit('sendOne')">Распознать документ</ui-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectAreaControls',
  props: {
    file: {
      type: File,
      required: true
    },
    arrAreas: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
.select-area-controls {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 250px;
  padding: 10px;
  background-color: #fff;

  @media (max-width: 1250px) {
    position: static;
    height: auto;
  }

  &__title {
    text-align: center;
    margin-bottom: 10px;
  }

  &__list {
    margin-bottom: 10px;
  }

  &__list-item {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;

    &:hover {}
  }

  &__empty {
    text-align: center;
  }

  &__button {
    margin-left: auto;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: auto;

    @media (max-width: 1250px) {
      margin-top: 20px;
    }
  }
}
</style>
