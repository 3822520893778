import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    arrFiles: []
  },
  mutations: {
    SET_ARR_FILES (state, arrFiles) {
      state.arrFiles = arrFiles
    }
  },
  actions: {
    setArrFiles ({ commit }, arrFiles) {
      commit('SET_ARR_FILES', arrFiles)
    }
  },
  getters: {
    arrFiles: state => state.arrFiles
  }
})
