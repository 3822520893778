var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"button",class:[
    { 'button--small': _vm.isSmall },
    { 'button--width': _vm.isWidth },
    { 'button--only-icon': !_vm.$slots.default && _vm.icon },
    { 'button--text': _vm.isText },
    `button--${_vm.color}`
  ],attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.icon)?_c('font-awesome-icon',{class:{ 'button__icon': _vm.$slots.default },attrs:{"icon":['far', _vm.icon],"fixed-width":""}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }