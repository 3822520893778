import global from '@/common/axios-global'

function dataFunc (sendingData, method = '') {
  // Создаем пустой объект
  if (typeof sendingData !== 'string') {
    const data = new FormData()
    // Добавляем в созданный объект поля
    // Если obj не null добавляем в data ключ-значени obj
    if (sendingData) Object.entries(sendingData).forEach(([key, value]) => data.append(key, value))

    if (method) data.append('_method', method)

    return data
  } else {
    return sendingData
  }
}

const ApiService = {
  get (url, params = {}, responseType = 'json') {
    return global.get(url, { params: params, responseType: responseType })
  },
  post (url, sendData = {}, params) {
    const formData = dataFunc(sendData)
    return global.post(url, formData, params)
  },
  patch (url, sendData = {}) {
    const formData = dataFunc(sendData)
    formData.append('_method', 'PATCH')
    return global.post(url, formData)
  },
  upload (url = '', params = {}, config = { headers: { 'Content-Type': 'multipart/form-data' } }) {
    const formData = dataFunc(params)
    return global.post(url, formData, config)
  }
}

export default ApiService
