import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import index from '../pages/index'
import areaSection from '../pages/select'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: index
  },
  {
    path: '/select',
    name: 'Select',
    component: areaSection,
    beforeEnter: (to, from, next) => {
      const isSelectedFile = () => {
        return store.getters.arrFiles.length
      }

      if (isSelectedFile()) {
        next()
      } else {
        next({ path: '/' })
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
