<template>
  <img class="icon" :alt="icon" :src="iconSrc" />
</template>

<script>
import LogoDescription from '@/assets/svg/LogoDescription.svg'
import LogoDescriptionFooter from '@/assets/svg/LogoDescriptionFooter.svg'
import Telegram from '@/assets/svg/Telegram.svg'
import Vaiber from '@/assets/svg/Vaiber.svg'
import Whatsup from '@/assets/svg/Whatsup.svg'
import Trash from '@/assets/svg/Trash.svg'

const icons = {
  'logo-description': LogoDescription,
  'logo-description-footer': LogoDescriptionFooter,
  telegram: Telegram,
  vaiber: Vaiber,
  whatsup: Whatsup,
  Trash: Trash
}

export default {
  name: 'UiIcon',

  props: {
    icon: {
      type: String,
      required: true,
      validator: (name) => Object.keys(icons).includes(name)
    }
  },

  computed: {
    iconSrc () {
      return icons[this.icon]
    }
  }
}
</script>

<style scoped>
.icon {
  display: block;
}
</style>
