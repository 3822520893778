<template>
  <div id="app">
    <layout-default>
      <router-view />
    </layout-default>
  </div>
</template>

<script>
import LayoutDefault from '@/layout/default'

export default {
  name: 'App',
  components: {
    LayoutDefault
  }
}
</script>

<style lang="scss">
@import './assets/scss/main';
</style>
