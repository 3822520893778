<template>
  <div class="select">
    <select-area :arr-files="arrFiles" />
  </div>
</template>

<script>
import SelectArea from '@/components/SelectArea'
import { mapGetters } from 'vuex'

export default {
  name: 'Select',
  components: {
    SelectArea
  },
  computed: {
    ...mapGetters(['arrFiles'])
  }
}
</script>
