<template>
 <button
   class="button"
   :class="[
     { 'button--small': isSmall },
     { 'button--width': isWidth },
     { 'button--only-icon': !$slots.default && icon },
     { 'button--text': isText },
     `button--${color}`
   ]"
   type="button"
   @click="$emit('click')"
 >
   <font-awesome-icon
     v-if="icon"
     :icon="['far', icon]"
     fixed-width
     :class="{ 'button__icon': $slots.default }"
   />
   <slot />
 </button>
</template>

<script>
export default {
  name: 'UiButton',
  props: {
    icon: {
      type: String,
      default: ''
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    isWidth: {
      type: Boolean,
      default: false
    },
    isText: {
      type: Boolean,
      default: false
    },
    isOutline: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'default'
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 44px;
  font-family: "RalewayExtraBold",sans-serif;
  font-size: 20px;
  letter-spacing: .4px;
  text-decoration: none;
  color: var(--white);
  border-radius: 30px;
  border: none;
  cursor: pointer;
  outline: none;

  @media (max-width: 500px) {
    font-size: 16px;
    padding: 12px 38px;
  }

  &__icon {
    margin-right: 5px;
  }
}

.button--default {
  box-shadow: var(--box-shadow-btn);
  background: var(--no-gradient) var(--linear-gradient) no-repeat;

  &:hover,
  &:focus {
    box-shadow: 0 0 6px 3px var(--color-shadow-hover-btn);
  }
}

.button--danger {
  background: var(--color-danger);
}

.button--success {
  background: var(--color-success);
}

.button--small {
  font-size: 16px;
  padding: 10px 20px;

  @media (max-width: 500px) {
    font-size: 14px;
    padding: 4px 10px;
  }
}

.button--only-icon {
  padding: 10px;
}

.button--text {
  color: inherit;
  background: none;
  box-shadow: none;
  padding: 0;
}

.button--text.button--danger {
  color: var(--color-danger);
}

.button--width {
    width: 100%;
  }
</style>
