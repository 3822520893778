<template>
  <div class="areas">
    <div
      @mousedown="setStart"
      @touchstart="setStart"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
      @mouseleave="clearData"
      @touchend="onMouseUp"
      @mouseup="onMouseUp"
      @dblclick="onDoubleClick"
      @dragstart.prevent.stop
      >
      <canvas v-if="getTypeFile === 'pdf'" id="theCanvas" />
      <div
        v-else
        id="preview"
      />
      <areas-item
        v-for="(item, itemIndex) of arrAreas"
        :key="itemIndex"
        :default-setting="item"
        :number="itemIndex + 1"
        @selectAreaItem="setObjCurrentArea"
      />
      <areas-item
        v-if="isAddingNewArea"
        :default-setting="objCurrentArea"
        is-hide-corner
      />
    </div>
  </div>
</template>

<script>
import AreasItem from '@/components/SelectArea/_components/areas/_components/AreasItem'

export default {
  name: 'SelectArea',
  components: {
    AreasItem
  },
  props: {
    file: {
      type: File,
      required: true
    },
    arrAreas: {
      type: Array,
      required: true
    },
    isDisabledAddArea: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      timer: null,
      isAddingNewArea: false,
      strMode: '',
      objStartPosition: {},
      objCurrentArea: {}
    }
  },
  computed: {
    getTypeFile () {
      return this.file.type.split('/')[1]
    }
  },
  mounted () {
    if (this.getTypeFile === 'pdf') {
      this.renderPdf()
    } else {
      this.renderImage()
    }

    setTimeout(() => {
      this.onMounted()
    }, 250)

    document.addEventListener('mouseup', this.clearData)
  },
  methods: {
    getClientCoordination (event) {
      const getAxis = (axis) => {
        return event.touches
          ? event.touches[0][`page${axis}`]
          : event[`page${axis}`]
      }

      return {
        localX: getAxis('X') - document.querySelector('.areas').offsetLeft,
        localY: getAxis('Y') - document.querySelector('.areas').offsetTop
      }
    },
    renderImage () {
      const reader = new FileReader()
      reader.readAsDataURL(this.file)
      reader.onloadend = function () {
        const img = document.createElement('img')
        img.src = reader.result
        document.getElementById('preview').appendChild(img)
      }
    },
    renderPdf () {
      const urlBlobFile = URL.createObjectURL(this.file)
      const PDFjs = window.pdfjsLib.getDocument({ url: urlBlobFile })

      PDFjs.promise
        .then((pdfDocument) => {
          // Request a first page
          pdfDocument.getPage(1).then((pdfPage) => {
            // Display page on the existing canvas with 100% scale.
            const viewport = pdfPage.getViewport({ scale: 1.5 })
            const canvas = document.getElementById('theCanvas')
            canvas.width = viewport.width
            canvas.height = viewport.height
            const ctx = canvas.getContext('2d')
            const renderTask = pdfPage.render({
              canvasContext: ctx,
              viewport
            })

            return renderTask.promise
          })
        })
        .catch(function (reason) {
          console.error('Error: ' + reason)
        })
    },
    onMounted () {
      this.$emit('mounted', { width: this.$el.offsetWidth, height: this.$el.offsetHeight })
    },
    setStart (event, axis) {
      const { localX, localY } = this.getClientCoordination(event)
      event.preventDefault()

      if (!Object.values(this.objCurrentArea).length && !this.isDisabledAddArea) {
        this.timer = setTimeout(() => {
          this.isAddingNewArea = true
          this.addNewArea(event)
        }, 250)
      }

      if (axis) {
        this.objStartPosition[axis] = axis === 'x' ? localX : localY
      } else {
        this.objStartPosition = { x: localX, y: localY }
      }
    },
    setObjCurrentArea (id, event) {
      const resize = Array.from(event.target.attributes).find(objItem => objItem.name === 'data-area-resize')

      if (resize) {
        this.strMode = resize.value
      } else {
        this.strMode = 'move'
      }

      this.objCurrentArea = this.arrAreas.find(item => item._innerId === id)
    },
    onDoubleClick (event) {
      const { localX, localY } = this.getClientCoordination(event)

      this.objCurrentArea = {
        height: 30,
        width: 30,
        x: localX,
        y: localY
      }

      this.$emit('addArea', this.objCurrentArea)
      this.clearData()
    },
    addNewArea (event) {
      const { localX, localY } = this.getClientCoordination(event)

      if (this.isAddingNewArea && !isFinite(this.objCurrentArea.x)) {
        this.objCurrentArea = {
          height: 1,
          width: 1,
          x: localX,
          y: localY
        }

        let direction = ''
        direction = this.objStartPosition.y - localY > 0 ? 'top' : 'bottom'
        direction += this.objStartPosition.x - localX > 0 ? '-left' : '-right'

        this.strMode = direction
      }
    },
    onMouseMove (event) {
      this.clearTimeout()

      if (!this.strMode && !this.isAddingNewArea) return

      event.preventDefault()

      if (this.isAddingNewArea && !this.isDisabledAddArea) {
        this.addNewArea(event)
      }

      if (this.strMode === 'move') {
        this.onAreaChangePosition(event)
      } else {
        this.onAreaResize(event)
      }
    },
    clearTimeout () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    onMouseUp () {
      this.clearTimeout()

      if (this.isAddingNewArea && this.objCurrentArea.width && this.objCurrentArea.height) {
        this.$emit('addArea', this.objCurrentArea)
      }

      this.clearData()
    },
    clearData () {
      if (this.objCurrentArea._innerId) {
        this.$emit('changeArea', this.objCurrentArea)
      }

      this.isAddingNewArea = false
      this.strMode = ''
      this.objCurrentArea = {}
      this.objStartPosition = {}
    },
    // Изменение размеров
    onAreaResize (event) {
      if (this.strMode === 'top-left') {
        this.onMoveCornerTop(event)
        this.onMoveCornerLeft(event)
      } else if (this.strMode === 'top-right') {
        this.onMoveCornerTop(event)
        this.onMoveCornerRight(event)
      } else if (this.strMode === 'bottom-left') {
        this.onMoveCornerBottom(event)
        this.onMoveCornerLeft(event)
      } else if (this.strMode === 'bottom-right') {
        this.onMoveCornerBottom(event)
        this.onMoveCornerRight(event)
      }
    },
    onMoveCornerTop (event) {
      const { localY } = this.getClientCoordination(event)

      const movingYPx = this.objStartPosition.y - localY
      const positionTop = this.objCurrentArea.y
      const changeHeight = this.objCurrentArea.height + movingYPx

      if (((positionTop - movingYPx <= 0)) && !this.isAddingNewArea) return

      if (changeHeight < 0) {
        this.strMode = this.strMode === 'top-left' ? 'bottom-left' : 'bottom-right'
        this.objStartPosition.y = localY - Math.abs(changeHeight)
        this.objCurrentArea.y = localY - Math.abs(changeHeight)
        // TODO: Вынести в метод
        this.objCurrentArea.height = Math.abs(changeHeight)
        this.setStart(event, 'y')
      } else {
        this.objCurrentArea.height = Math.abs(changeHeight)
        this.moveY(event)
      }
    },
    onMoveCornerRight (event) {
      const { localX } = this.getClientCoordination(event)
      const movingXPx = localX - this.objStartPosition.x
      const positionLeft = this.objCurrentArea.x
      const changeWidth = this.objCurrentArea.width + movingXPx

      if (((positionLeft + changeWidth >= this.$el.offsetWidth)) && !this.isAddingNewArea) return

      if (changeWidth < 0) {
        this.strMode = this.strMode === 'top-right' ? 'top-left' : 'bottom-left'
        this.objStartPosition.x = localX - Math.abs(changeWidth)
        this.objCurrentArea.x = localX - Math.abs(changeWidth)
        this.objCurrentArea.width = Math.abs(changeWidth)
        this.moveX(event)
      } else {
        this.objCurrentArea.width = Math.abs(changeWidth)
        this.setStart(event, 'x')
      }
    },
    onMoveCornerBottom (event) {
      const { localY } = this.getClientCoordination(event)
      const movingYPx = localY - this.objStartPosition.y
      const positionTop = this.objCurrentArea.y
      const changeHeight = this.objCurrentArea.height + movingYPx

      if (((positionTop + changeHeight >= this.$el.offsetHeight)) && !this.isAddingNewArea) return

      if (changeHeight < 0) {
        this.strMode = this.strMode === 'bottom-right' ? 'top-right' : 'top-left'
        this.objStartPosition.y = localY - Math.abs(changeHeight)
        this.objCurrentArea.y = localY - Math.abs(changeHeight)
        this.objCurrentArea.height = Math.abs(changeHeight)
        this.moveY(event)
      } else {
        this.objCurrentArea.height = Math.abs(changeHeight)
        this.setStart(event, 'y')
      }
    },
    onMoveCornerLeft (event) {
      const { localX } = this.getClientCoordination(event)
      const movingXPx = this.objStartPosition.x - localX
      const positionLeft = this.objCurrentArea.x
      const changeWidth = this.objCurrentArea.width + movingXPx

      if (((positionLeft - movingXPx <= 0)) && !this.isAddingNewArea) return

      if (changeWidth < 0) {
        this.strMode = this.strMode === 'top-left' ? 'top-right' : 'bottom-right'
        this.objStartPosition.x = localX - Math.abs(changeWidth)
        this.objCurrentArea.x = localX - Math.abs(changeWidth)
        this.objCurrentArea.width = Math.abs(changeWidth)
        this.setStart(event, 'x')
      } else {
        this.objCurrentArea.width = Math.abs(changeWidth)
        this.moveX(event)
      }
    },
    // Перемещение
    onAreaChangePosition (event) {
      if (this.objCurrentArea && !this.objCurrentArea._innerId) return

      this.moveX(event)
      this.moveY(event)
    },
    moveX (event) {
      const { localX } = this.getClientCoordination(event)
      const width = this.objCurrentArea.width
      const left = this.objCurrentArea.x + (localX - this.objStartPosition.x)

      if (left >= 0 && left + width <= this.$el.clientWidth) {
        this.objCurrentArea.x = left
      } else if (left + width >= this.$el.clientWidth) {
        this.objCurrentArea.x = this.$el.clientWidth - width
      } else {
        this.objCurrentArea.x = 0
      }

      this.objStartPosition.x = localX
    },
    moveY (event) {
      const { localY } = this.getClientCoordination(event)
      const height = this.objCurrentArea.height
      const top = this.objCurrentArea.y + (localY - this.objStartPosition.y)

      if (top >= 0 && top + height <= this.$el.clientHeight) {
        this.objCurrentArea.y = top
      } else if (top + height >= this.$el.clientHeight) {
        this.objCurrentArea.y = this.$el.clientHeight - height
      } else {
        this.objCurrentArea.y = 0
      }

      this.objStartPosition.y = localY
    }
  }
}
</script>

<style lang="scss">
.areas {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
  height: fit-content;
  max-width: 100%;
  max-height: 100%;

  img {
    display: flex;
    min-height: 100%;
    width: 100%;
    object-fit: contain;
    user-select: none;
  }

  canvas {
    display: flex;
    width: 100%;
    min-height: 100%;
  }
}
</style>
