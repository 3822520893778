<template>
  <div
    class="select-area-item"
    :style="`
        top: ${defaultSetting.y}px;
        left: ${defaultSetting.x}px;
        width: ${defaultSetting.width}px;
        height: ${defaultSetting.height}px;
      `"
    :class="{ 'select-area-item--is-new-area': isHideCorner }"
    @mousedown="selectAreaItem"
    @touchstart="selectAreaItem"
  >
    <span class="select-area-item__number">{{ number ? `# ${number}` : 'new' }}</span>
    <div v-if="!isHideCorner" class="area-arrow">
      <div class="area-arrow-top-left" data-area-resize="top-left" />
      <div class="area-arrow-top-right" data-area-resize="top-right" />
      <div class="area-arrow-bottom-left" data-area-resize="bottom-left" />
      <div class="area-arrow-bottom-right" data-area-resize="bottom-right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectAreaItem',
  props: {
    defaultSetting: {
      type: Object
    },
    number: {
      type: Number,
      default: null
    },
    isHideCorner: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      settings: {
        width: 0,
        height: 0,
        y: 0,
        x: 0
      },
      start: 0
    }
  },
  created () {
    this.settings = { ...this.defaultSetting }
  },
  methods: {
    selectAreaItem (event) {
      this.$emit('selectAreaItem', this.defaultSetting._innerId, event)
    }
  }
}
</script>

<style lang="scss">
.select-area-item {
  --area-color: #8BE0AD30;
  --area-border-color: #8BE0ADFF;
  position: absolute;
  background-color: var(--area-color);

  &:hover &__number {
    display: block;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    right: 15px;
    border-top: 1px solid var(--area-border-color);
    border-bottom: 1px solid var(--area-border-color);
    opacity: .8;
  }

  &::after {
    content: '';
    position: absolute;
    top: 15px;
    bottom: 15px;
    left: 0;
    right: 0;
    border-left: 1px solid var(--area-border-color);
    border-right: 1px solid var(--area-border-color);
    opacity: .8;
  }

  &__number {
    display: none;
    position: absolute;
    top: -18px;
    font-size: 10px;
    color: black;
    background-color: var(--area-border-color);
    padding: 3px;
    white-space: nowrap;
  }
}

.select-area-item--is-new-area {
  &::before,
  &::after {
    display: none;
  }

  .select-area-item__number {
    display: block;
  }
}

.area-arrow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  cursor: grab;

  .area-arrow-top-left,
  .area-arrow-top-right,
  .area-arrow-bottom-left,
  .area-arrow-bottom-right {
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: 1;
    cursor: crosshair;

    &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 8px;
      background-color: var(--area-border-color);
    }

    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 2px;
      background-color: var(--area-border-color);
    }
  }

  .area-arrow-top-left {
    top: 0;
    left: 0;

    &::after,
    &::before {
      top: 0;
      left: 0;
    }
  }

  .area-arrow-top-right {
    top: 0;
    right: 0;

    &::after,
    &::before {
      top: 0;
      right: 0;
    }
  }

  .area-arrow-bottom-left {
    bottom: 0;
    left: 0;

    &::after,
    &::before {
      bottom: 0;
      left: 0;
    }
  }

  .area-arrow-bottom-right {
    bottom: 0;
    right: 0;

    &::after,
    &::before {
      bottom: 0;
      right: 0;
    }
  }
}

.rect {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: green;
  cursor: col-resize;
}
</style>
