<template>
  <div class="select-area">
    <select-area-controls
      :file="arrFiles[0]"
      :arr-areas="arrAreas"
      @removeArea="removeArea"
      @changeApi="changeApi"
      @sendOne="sendOne"
    />

    <div class="select-area__wrap">
      <areas
        :file="arrFiles[0]"
        :arr-areas="arrAreas"
        :isDisabledAddArea="isDisabledAddArea"
        @addArea="addArea"
        @changeArea="changeArea"
        @mounted="onMounted"
      />
    </div>

    <vm-modal
      name="select-area"
      height="auto"
      width="500"
      :adaptive="true"
      :click-to-close="false"
    >
      <div class="select-area-result">
        <h3 class="select-area-result__title">Результат распознавания</h3>
        <template v-if="objRecognitionResult">
          <strong class="select-area-result__filename">{{ `Файл: ${objRecognitionResult.filename}` }}</strong>

          <ul class="select-area-result__list">
            <li
              v-for="(item, indexItem) in objRecognitionResult.results"
              :key="indexItem"
            >
              {{ `Область #${indexItem + 1} - ${item}` }}
            </li>
          </ul>
        </template>

        <strong v-else class="select-area-result__status">
          {{ labelStatusResult }}
        </strong>

        <template v-if="statusResult !== 'load'">
          <div class="select-area-result__controls">
            <ui-button is-small @click="resetSettings">Попробовать снова</ui-button>
            <ui-button is-small @click="completeRecognition">Завершить</ui-button>
          </div>

          <span class="select-area-result__footnote">
            Вы используете тестовую версию сервиса. Результат распознавания может быть недостаточно точным. Мы работаем над этим! :)
          </span>
        </template>
      </div>
    </vm-modal>

    <vm-modal
      name="modal-change-api"
      height="auto"
      width="500"
      :adaptive="true"
    >
      <div class="recognition-api">
        <h3 class="recognition-api__title">Выбрать модель распознавания</h3>
        <ul class="recognition-api__list">
          <li class="recognition-api__list-item" @click="onChange">
            <label>
              <input type="radio" name="recognition" value="1" checked>
              Loopa Light
            </label>
          </li>
        </ul>
      </div>
    </vm-modal>
  </div>
</template>

<script>
import Areas from '@/components/SelectArea/_components/areas'
import SelectAreaControls from '@/components/SelectArea/_components/controls'
import ApiService from '@/common/api.service'

export default {
  name: 'SelectArea',
  components: {
    SelectAreaControls,
    Areas
  },
  props: {
    arrFiles: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      sizeFile: {},
      arrAreas: [],
      statusResult: 'load',
      objRecognitionResult: null
    }
  },
  computed: {
    labelStatusResult () {
      return {
        load: 'Загрузка...',
        error: 'Ошибка, перезагрузите страницу',
        empty: 'Выберите хотя бы одну область'
      }[this.statusResult]
    },
    isDisabledAddArea () {
      return this.arrAreas.length === 5
    }
  },
  created () {
    this.setDefaultAreas()
  },
  methods: {
    onChange () {
      this.$toasted.show('Вы используете тестовую версию сервиса.', {
        position: 'bottom-center',
        duration: 5000
      })
    },
    completeRecognition () {
      this.$store.dispatch('setArrFiles', [])
      this.$router.push({ path: '/' })
    },
    resetSettings () {
      this.$modal.hide('select-area')
      this.setDefaultAreas()
      this.objRecognitionResult = null
    },
    setDefaultAreas () {
      this.arrAreas = []
    },
    changeArea (objChangeArea) {
      const objTemp = this.arrAreas.find(objItem => objItem._innerId === objChangeArea._innerId)
      objTemp.height = objChangeArea.height
      objTemp.x = objChangeArea.x
      objTemp.y = objChangeArea.y
      objTemp.width = objChangeArea.width
    },
    addArea (objArea) {
      if (this.arrAreas.length === 5) return

      this.arrAreas.push({
        _innerId: Math.random().toString(36).slice(7),
        ...objArea
      })
    },
    removeArea (innerId) {
      this.arrAreas = this.arrAreas.filter(objItem => objItem._innerId !== innerId)
    },
    onMounted ({ width, height }) {
      this.sizeFile = {
        width: width,
        height: height
      }
    },
    changeApi () {
      this.$modal.show('modal-change-api')
    },
    sendOne () {
      if (!this.arrAreas.length) {
        this.statusResult = 'empty'
        this.$modal.show('select-area')
        return
      }

      this.statusResult = 'load'

      const sendData = {}

      this.arrAreas.forEach((objArea, indexArea) => {
        sendData[`areas[${indexArea}][width]`] = objArea.width
        sendData[`areas[${indexArea}][height]`] = objArea.height
        sendData[`areas[${indexArea}][top]`] = objArea.y
        sendData[`areas[${indexArea}][left]`] = objArea.x
      })

      sendData['files[0][file]'] = this.arrFiles[0]
      sendData['files[0][name]'] = this.arrFiles[0].name
      sendData['files[0][width]'] = this.sizeFile.width
      sendData['files[0][height]'] = this.sizeFile.height

      this.$modal.show('select-area')

      ApiService.upload('upload', sendData)
        .then(({ data }) => {
          Object.entries(data).forEach(([filename, arrResult]) => {
            this.objRecognitionResult = {
              filename: filename,
              results: arrResult.length ? arrResult : ['Текст не распознан']
            }
          })

          this.statusResult = 'success'
        })
        .catch((err) => {
          console.error(err)
          this.statusResult = 'error'
        })
    }
  }
}
</script>

<style lang="scss">
.select-area {
  position: relative;
  display: grid;
  grid-template-columns: 250px minmax(auto, 1fr);
  min-height: 100%;
  width: 100%;
  max-width: 100%;
  background-color: #fbfbfb;

  @media (max-width: 1250px) {
    grid-template-columns: 1fr;
  }

  &__wrap {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: #333;
  }
}

.select-area-result {
  padding: 15px;

  &__title {
    margin-bottom: 24px;
  }

  &__status {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  &__filename {
    display: inline-block;
    margin-bottom: 10px;
  }

  &__list {
    margin-bottom: 24px;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 15px;
  }

  &__footnote {
    font-size: 10px;
  }
}

.recognition-api {
  padding: 12px;

  &__title {
    margin-bottom: 12px;
  }

  &__list-item {
    margin-bottom: 6px;
  }
}
</style>
