<template>
  <div class="layout-default">
    <the-header v-if="isShowHeaderPage" />
    <slot />
    <the-footer v-if="isShowHeaderPage" />
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader'
import TheFooter from '@/components/TheFooter'

export default {
  name: 'Default',
  components: {
    TheHeader,
    TheFooter
  },
  computed: {
    isShowHeaderPage () {
      return this.$route.name !== 'Select'
    }
  }
}
</script>

<style lang="scss">
.layout-default {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
}
</style>
