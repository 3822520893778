// import { fas } from '@fortawesome/pro-solid-svg-icons'
// library.add(fas) // весит около 1 мб

/*  МАНУАЛ
*   ГДЕ СМОТРЕТЬ ИКОНКИ? https://fontawesome.com/icons?d=gallery&m=pro
*   Убедитесь, что используется фильтр Pro Only
*
*   Как использовать:
*   нужно импортировать с префиксом fa название выбранной иконки
*
*   Примечание: WebStorm помогает с названием импортов
*   Примечание 2: полный пакет иконок fab весит чуть больше 1мб.
*   Импорт списка ниже чуть больше 100кб (11.03.2019)
*
*   Далее нужно добавить название импорта в переменную icons
*
*   После этого можно пользоваться иконками в любом разделе, используя компонент:
*   <font-awesome-icon icon="archive" size="2x" />
*   Документация по компоненту: https://github.com/FortAwesome/vue-fontawesome
*
*   ВАЖНО!!!! Добавляйте иконки в АЛФАВИТНОМ ПОРЯДКЕ!
* */
import {
  faTrashAlt as farTrashAlt,
  faArrowRight as farArrowRight
} from '@fortawesome/pro-regular-svg-icons'

export const icons = {
  // A
  farArrowRight,
  // B
  // C
  // D
  // E
  // F
  // G
  // H
  // I
  // G
  // K
  // L
  // M
  // N
  // O
  // P
  // Q
  // R
  // S
  // T
  farTrashAlt
  // U
  // V
  // X, Y, Z
}
